.success-icon {
  display: inline-block;
  width: 10em;
  height: 10em;
  font-size: 8px;
  border-radius: 50%;
  border: 8px solid #68d85d;
  position: relative;
  overflow: hidden;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-animation: showSuccess 180ms ease-in-out;
          animation: showSuccess 180ms ease-in-out;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.success-icon__tip, .success-icon__long {
  display: block;
  position: absolute;
  height: 1em;
  background-color: #68d85d;
  border-radius: 1em;
}

.success-icon__tip {
  width: 2.8em;
  top: 4.3em;
  left: 1.4em;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-animation: tipInPlace 300ms ease-in-out;
          animation: tipInPlace 300ms ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 180ms;
          animation-delay: 180ms;
  visibility: hidden;
}

.success-icon__long {
  width: 4.4em;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  top: 3.80em;
  left: 2.55em;
  -webkit-animation: longInPlace 140ms ease-in-out;
          animation: longInPlace 140ms ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  visibility: hidden;
  -webkit-animation-delay: 440ms;
          animation-delay: 440ms;
}

@-webkit-keyframes showSuccess {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes showSuccess {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes tipInPlace {
  from {
    width: 0em;
    top: 3.5em;
    left: 2em;
  }
  to {
    width: 2.8em;
    top: 4.3em;
    left: 1.4em;
    visibility: visible;
  }
}

@keyframes tipInPlace {
  from {
    width: 0em;
    top: 3.5em;
    left: 2em;
  }
  to {
    width: 2.8em;
    top: 4.3em;
    left: 1.4em;
    visibility: visible;
  }
}

@-webkit-keyframes longInPlace {
  from {
    width: 0em;
    top: 5.1em;
    left: 3.2em;
  }
  to {
    width: 4.4em;
    top: 3.80em;
    left: 2.55em;
    visibility: visible;
  }
}

@keyframes longInPlace {
  from {
    width: 0em;
    top: 5.1em;
    left: 3.2em;
  }
  to {
    width: 4.4em;
    top: 3.80em;
    left: 2.55em;
    visibility: visible;
  }
}