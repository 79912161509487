* {
  box-sizing: border-box;
}

body,
html {
  font-size: 18px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

h1 {
  color: #32325d;
  font-weight: 400;
  line-height: 50px;
  font-size: 40px;
  margin: 20px 0;
  padding: 0;
}

label, p {
  color: #3e4c5f;
}

input, select,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
  width: 100%;
}

input::placeholder {
  color: #aab7c4;
}

input:focus,
select:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.PaymentRequestButton {
  padding: 0;
}

.stripe-link {
  color: #386cb3;
  padding: 0;
  border: none;
  font-size: 1em;
  background: none;
  text-decoration: underline;
  cursor: pointer;
}

.stripe-link:hover {
  color: #519bff;
}

.stripe-link:active {
  color: #024eb7;
}

.stripe-link.margin {
  margin-top: 1.5em;
}

.center-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}
